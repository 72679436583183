<template>
    <b-overlay :show='showLoader'>
        <validation-observer ref="addPaperValidation">
            <b-form @submit.prevent>
                <b-card>
                    <b-row>
                        <b-col><h3>{{ $t("general.add_paper") }}</h3></b-col>
                        <b-col class="text-right">
                            <b-button variant='warning' @click="$router.push({name: 'faq'})">{{ $t('general.help')}}</b-button>
                            <b-button variant="secondary" class="ml-2" @click='$router.go(-1)'>{{ $t('general.go_back')}}</b-button>
                        </b-col>
                    </b-row>

                    <b-row class="mt-1">
                        <b-col cols='12' sm='6'>
                            <validation-provider
                                :name="$t('general.school_year')"
                                #default="{ errors }"
                                rules="required"
                            >
                                <label for="school_year">{{ $t('general.school_year') }}</label>
                                <v-select id='school_year' :clearable='false' disabled label="Name" v-model="selected_year"></v-select>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>

                        </b-col>
                        <b-col cols='12' sm='6' class='mt-1 mt-sm-0'>
                            <validation-provider
                                :name="$t('general.type')"
                                #default="{ errors }"
                                rules="required"
                            >
                                <label for="type">{{ $t('general.type') }}</label>
                                <v-select id="type" :clearable='false' :options='types' label="Name" v-model="selected_type"></v-select>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-col>
                        <!-- <b-col cols='12' sm='12' md='6' class='mt-1 mt-md-0'>
                            <label for="school">{{ $t('general.school') }}</label>
                            <v-select @input='onSchoolChange' label='Naziv' :clearable='false' :options='schoolList' id='school' v-model="selected_school"></v-select>
                        </b-col> -->
                    </b-row>

                    <b-row class='mt-1'>
                        <b-col>
                            <validation-provider
                                :name="$t('general.title')"
                                #default="{ errors }"
                                rules="required|max:250"
                            >
                                <label for='title'>{{ $t('general.title') }}</label>
                                <b-form-input id='title' v-model="title"></b-form-input>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-col>
                    </b-row>

                    <template v-if="userRole === 'admin'">

                        <b-row class='mt-1'>
                            <b-col cols='12'  sm='6'>
                                <validation-provider
                                    :name="$t('general.number_of_points')"
                                    #default="{ errors }"
                                    rules=""
                                >
                                    <label for='number_of_points'>{{ $t('general.number_of_points') }}</label>
                                    <b-form-input placeholder="Največ 3 znaki" v-model="number_of_points" id='number_of_points'></b-form-input>
                                    <small  class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-col>
                            <b-col cols='12' sm='6' class='mt-1 mt-sm-0'>
                                <validation-provider
                                    :name="$t('general.place')"
                                    #default="{ errors }"

                                >
                                    <label for='place'>{{ $t('general.place') }}</label>
                                    <b-form-input placeholder="Največ 3 znaki" v-model="place" id='place'></b-form-input>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-col>
                        </b-row>
                        <b-row class='mt-1'>
                            <b-col>
                                <label for='award'>{{ $t('general.award') }}</label>
                                <b-form-textarea v-model="award" id='award'></b-form-textarea>
                            </b-col>
                        </b-row>
                    </template>

                    <b-row class="mt-1">
                        <b-col cols='12' md='6'>
                            <validation-provider
                                :name="$t('general.area')"
                                #default="{ errors }"
                                rules="required"
                            >
                                <label for="selected_area">{{ $t('general.area') }}</label>
                                <v-select @input='areasLimiter' label='Name' multiple :options='areas' id='selected_area' v-model="selectedArea"></v-select>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>

                        </b-col>
                        <b-col cols='12' md='6' class='mt-1 mt-md-0'>
                            <label for="interdisciplinary">{{ $t('general.interdisciplinary') }}</label>
                            <b-form-checkbox id="interdisciplinary" @input='onInterdisciplinaryChange' v-model="isInterdisciplinary"></b-form-checkbox>
                        </b-col>
                    </b-row>


                    <b-row class="mt-1">
                        <b-col cols='12' md='6'>
                            <validation-provider
                                :name="$t('general.authors')"
                                #default="{ errors }"
                                rules="required"
                            >
                                <label for="authors">{{ $t('general.authors') }}</label>
                                <v-select @input='authorsLimiter' :getOptionLabel='getPersonLabel'  multiple :options='authors' id='authors' v-model="selected_authors"></v-select>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-col>
                        <b-col cols='12' md='6' class='mt-1 mt-md-0'>
                            <validation-provider
                                :name="$t('general.menthors')"
                                #default="{ errors }"
                                rules="required"
                            >
                                <label for="menthors">{{ $t('general.menthors') }}</label>
                                <v-select @input="menthorsLimiter" :getOptionLabel='getFullnameFromMentor' label="Firstname" multiple :options='menthors' id='menthors' v-model="selected_menthors"></v-select>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-col>
                    </b-row>
                    <b-row class='mt-1'>
                        <b-col>
                            <validation-provider
                                :name="$t('general.summary')"
                                #default="{ errors }"
                                rules="required"
                            >
                                <label for='summary'>{{ $t('general.summary') }}</label>
                                <b-form-textarea v-model="summary" id='summary'></b-form-textarea>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-col>
                    </b-row>
                    <b-row class="mt-1">
                        <b-col class="text-right">
                            <b-button @click="validationForm" variant="primary">{{ $t('general.add') }}</b-button>
                        </b-col>
                    </b-row>
                </b-card>
            </b-form>
        </validation-observer>
    </b-overlay>
</template>
<script>
    import {BCol, BRow, BForm, BOverlay, BCard, BFormInput, BFormTextarea, BButton, BFormCheckbox} from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import {ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required, max} from '@validations'
    export default {
        components: {BCol, BRow, BForm, BOverlay, BCard, BFormInput, BFormTextarea, BButton, BFormCheckbox, vSelect, ValidationProvider, ValidationObserver},
        data() {
            return {
                showLoader: false,
                years: [],
                selected_year: null,
                schoolList: [],
                selected_school: null,
                types: [],
                selected_type: null,

                fields: [
                    {
                        key: 'type',
                        label: this.$t('general.type')
                    },
                    {
                        key: 'area',
                        label: this.$t('general.area')
                    },
                    {
                        key: 'paper',
                        label: this.$t('general.paper')
                    }
                ],
                papersResults: [],
                authors: [],
                selected_authors: [],

                menthors: [],
                selected_menthors: [],
                
                areas: [],
                selectedArea: null,

                title: '',
                number_of_points: '',
                place: '',
                award: '',
                summary: '',
                isInterdisciplinary: false,

                userRole: 'sola',
                isChangingPermitted: false,

                required,
                max
            }
        },

        methods: {
            onInterdisciplinaryChange(isChecked) {
                if (!isChecked) {
                    this.selectedArea.splice(1, this.selectedArea.length - 1)
                }
            },
            areasLimiter(e) {
                // ce ni interdisciplinarno, lahko izbere samo eno področje
                if (!this.isInterdisciplinary && e.length > 1) {
                    e.pop()
                }

                let limit = 2
                if (this.$store.state.user.userData.SchoolType.Abbreviation === 'SŠ') limit = 3

                // OŠ lahko izbere max 2 področja, SŠ pa max 3
                if (e.length > limit) {
                    e.pop()
                }
            },
            menthorsLimiter(e) {
                if (e.length > 3) e.pop()
            },
            authorsLimiter(e) {
                if (e.length > 3) e.pop()
            },
            getPersonLabel(person) {
                return `${person.Firstname} ${person.Lastname}`
            },
            getFullnameFromMentor(person) {
                return `${person.ProfessionalTitle} ${person.Firstname} ${person.Lastname}`
            },
            async assembleParalelPromise() {
                const p1 = this.$http.get('/v1/sola')
                const p2 = this.$http.get('/v1/sola/podrocja')
                return {
                    schoolsResponse: await p1,
                    themesResponse: await p2
                }
            },
            validationForm() {
                this.$refs.addPaperValidation.validate().then((response) => {
                    if (response) {
                        this.onAddPaper()
                    }
                })
            },
            async checkIfChangeIsPermitted() {

                this.isChangingPermitted = await this.$checkIfChangeIsPermitted()
            },
            async loadData() {

                const thisIns = this
                thisIns.showLoader = true

                try {
                    const { schoolsResponse, themesResponse } = await this.assembleParalelPromise()

                    thisIns.schoolList = schoolsResponse.data
                    thisIns.areas = themesResponse.data

                    await this.checkIfChangeIsPermitted()

                    if (!this.isChangingPermitted) this.$router.replace('/')

                } catch (error) {
                    thisIns.$printError(`${thisIns.$t('general.load_error')}\n${error.message}`)
                } finally {
                    thisIns.showLoader = false
                }
            },
            onAddPaper() {
                const thisIns = this
                if (this.isInterdisciplinary && thisIns.selectedArea.length <= 1) {
                    this.$printWarning(this.$t('general.interdisciplinary_two_areas_selected'))
                    return
                }
                thisIns.showLoader = true

                const podrocjaID = thisIns.selectedArea
                const avtorjiID = thisIns.selected_authors
                const mentorID = thisIns.selected_menthors

                // ce je interdisciplinarno podrocje, posljem ID podrocja kot single ID
                // drugace pa posljem array IDjev
                const payload = {
                    InterdisciplinaryID: (thisIns.isInterdisciplinary ? 1 : 2),
                    SchoolYearID: thisIns.selected_year.ID,
                    TypeID: thisIns.selected_type.ID,
                    Title: thisIns.title,
                    Summary: thisIns.summary,
                    Authors: avtorjiID,
                    Mentors: mentorID,
                    Fields: podrocjaID
                }

                thisIns.$http.put('/v1/sola/naloga', payload)
                    .then(() => {
                        thisIns.$printSuccess(`${thisIns.$t('general.paper_successfully_added')}`)

                        thisIns.showLoader = false

                        setTimeout(() => {
                            thisIns.$router.push({name: 'school-research-papers'})
                        }, 500)
                    })
                    .catch(error => {
                        thisIns.$printError(`${thisIns.$t('general.add_error')}\n${error.message}`)
                        thisIns.showLoader = false
                    })

            },
            getSchoolYears() {
                const thisIns = this
                thisIns.showLoader = true
                thisIns.$http.get('/v1/sola/solskoLeto')
                    .then((response) => {
                        thisIns.years = response.data
                        for (const year of response.data) {
                            if (year.Active === 1) {
                                thisIns.selected_year = year
                            }
                        }
                        thisIns.showLoader = false
                    })
                    .catch(error => {
                        thisIns.$printError(`${thisIns.$t('general.load_error')}\n${error.message}`)
                        thisIns.showLoader = false
                    })
            },
            getSchoolTypes() {
                const thisIns = this
                thisIns.showLoader = true
                thisIns.$http.get('/v1/sola/vrstaNaloge')
                    .then((response) => {
                        thisIns.types = response.data
                        thisIns.selected_type = thisIns.types[0]
                        thisIns.showLoader = false
                    })
                    .catch(error => {
                        thisIns.$printError(`${thisIns.$t('general.load_error')}\n${error.message}`)
                        thisIns.showLoader = false
                    })
            },
            getAuthors() {
                const thisIns = this
                thisIns.showLoader = true
                thisIns.$http.get('/v1/sola/avtorji')
                    .then(res => {

                        if (res.data) thisIns.authors = res.data
                        thisIns.showLoader = false
                    })
                    .catch(error => {
                        thisIns.$printError(`${thisIns.$t('general.load_error')}${error.message}`)
                        thisIns.showLoader = false
                    })
            },
            getMentors() {
                const thisIns = this
                thisIns.showLoader = true
                thisIns.$http.get('/v1/sola/mentorji')
                    .then(res => {

                        if (res.data) thisIns.menthors = res.data
                        thisIns.showLoader = false
                    })
                    .catch(error => {
                        thisIns.$printError(`${thisIns.$t('general.load_error')}${error.message}`)
                        thisIns.showLoader = false
                    })
            }
        },
        created() {
            this.getSchoolYears()
            this.getSchoolTypes()
            this.getAuthors()
            this.getMentors()
            this.userRole = this.$store.state.user.userData.user_role
        },
        async mounted() {
            await this.loadData()
        }
    }
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
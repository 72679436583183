var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.showLoader}},[_c('validation-observer',{ref:"addPaperValidation"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-card',[_c('b-row',[_c('b-col',[_c('h3',[_vm._v(_vm._s(_vm.$t("general.add_paper")))])]),_c('b-col',{staticClass:"text-right"},[_c('b-button',{attrs:{"variant":"warning"},on:{"click":function($event){return _vm.$router.push({name: 'faq'})}}},[_vm._v(_vm._s(_vm.$t('general.help')))]),_c('b-button',{staticClass:"ml-2",attrs:{"variant":"secondary"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(_vm._s(_vm.$t('general.go_back')))])],1)],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('general.school_year'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"school_year"}},[_vm._v(_vm._s(_vm.$t('general.school_year')))]),_c('v-select',{attrs:{"id":"school_year","clearable":false,"disabled":"","label":"Name"},model:{value:(_vm.selected_year),callback:function ($$v) {_vm.selected_year=$$v},expression:"selected_year"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"mt-1 mt-sm-0",attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('general.type'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"type"}},[_vm._v(_vm._s(_vm.$t('general.type')))]),_c('v-select',{attrs:{"id":"type","clearable":false,"options":_vm.types,"label":"Name"},model:{value:(_vm.selected_type),callback:function ($$v) {_vm.selected_type=$$v},expression:"selected_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',[_c('validation-provider',{attrs:{"name":_vm.$t('general.title'),"rules":"required|max:250"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"title"}},[_vm._v(_vm._s(_vm.$t('general.title')))]),_c('b-form-input',{attrs:{"id":"title"},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(_vm.userRole === 'admin')?[_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('general.number_of_points'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"number_of_points"}},[_vm._v(_vm._s(_vm.$t('general.number_of_points')))]),_c('b-form-input',{attrs:{"placeholder":"Največ 3 znaki","id":"number_of_points"},model:{value:(_vm.number_of_points),callback:function ($$v) {_vm.number_of_points=$$v},expression:"number_of_points"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,486363248)})],1),_c('b-col',{staticClass:"mt-1 mt-sm-0",attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('general.place')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"place"}},[_vm._v(_vm._s(_vm.$t('general.place')))]),_c('b-form-input',{attrs:{"placeholder":"Največ 3 znaki","id":"place"},model:{value:(_vm.place),callback:function ($$v) {_vm.place=$$v},expression:"place"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1675257456)})],1)],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',[_c('label',{attrs:{"for":"award"}},[_vm._v(_vm._s(_vm.$t('general.award')))]),_c('b-form-textarea',{attrs:{"id":"award"},model:{value:(_vm.award),callback:function ($$v) {_vm.award=$$v},expression:"award"}})],1)],1)]:_vm._e(),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('general.area'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"selected_area"}},[_vm._v(_vm._s(_vm.$t('general.area')))]),_c('v-select',{attrs:{"label":"Name","multiple":"","options":_vm.areas,"id":"selected_area"},on:{"input":_vm.areasLimiter},model:{value:(_vm.selectedArea),callback:function ($$v) {_vm.selectedArea=$$v},expression:"selectedArea"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"mt-1 mt-md-0",attrs:{"cols":"12","md":"6"}},[_c('label',{attrs:{"for":"interdisciplinary"}},[_vm._v(_vm._s(_vm.$t('general.interdisciplinary')))]),_c('b-form-checkbox',{attrs:{"id":"interdisciplinary"},on:{"input":_vm.onInterdisciplinaryChange},model:{value:(_vm.isInterdisciplinary),callback:function ($$v) {_vm.isInterdisciplinary=$$v},expression:"isInterdisciplinary"}})],1)],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('general.authors'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"authors"}},[_vm._v(_vm._s(_vm.$t('general.authors')))]),_c('v-select',{attrs:{"getOptionLabel":_vm.getPersonLabel,"multiple":"","options":_vm.authors,"id":"authors"},on:{"input":_vm.authorsLimiter},model:{value:(_vm.selected_authors),callback:function ($$v) {_vm.selected_authors=$$v},expression:"selected_authors"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"mt-1 mt-md-0",attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('general.menthors'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"menthors"}},[_vm._v(_vm._s(_vm.$t('general.menthors')))]),_c('v-select',{attrs:{"getOptionLabel":_vm.getFullnameFromMentor,"label":"Firstname","multiple":"","options":_vm.menthors,"id":"menthors"},on:{"input":_vm.menthorsLimiter},model:{value:(_vm.selected_menthors),callback:function ($$v) {_vm.selected_menthors=$$v},expression:"selected_menthors"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',[_c('validation-provider',{attrs:{"name":_vm.$t('general.summary'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"summary"}},[_vm._v(_vm._s(_vm.$t('general.summary')))]),_c('b-form-textarea',{attrs:{"id":"summary"},model:{value:(_vm.summary),callback:function ($$v) {_vm.summary=$$v},expression:"summary"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{staticClass:"text-right"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.validationForm}},[_vm._v(_vm._s(_vm.$t('general.add')))])],1)],1)],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }